import storage, {StorageKey as SK} from "./storage";
import axios from "axios";
import {ErrCode, User} from "./types";
import store from "./store";

class AdminAPI {
     //BASE_URL = 'http://192.168.0.162/api'
    BASE_URL = 'https://admin.fnsvalue.co.kr/api'
    // BASE_URL = 'http://10.10.0.12:8433/api'
    // BASE_URL = 'http://52.141.62.186:8433/api'

    api = null;
    refreshApi = null;
    accessToken = null;
    refreshToken = null;
    token = null;
    refreshInProgress = false; // Add a flag to track refresh attempts

    CLIENT_KEY = 'd42663ec420e42b386f95002a10856fd';

    constructor() {
        //this.token = storage.getSession(SK.ACCESS_TOKEN);
        this.accessToken = storage.getSession(SK.ACCESS_TOKEN);
        this.refreshToken = storage.getSession(SK.REFRESH_TOKEN);

        this.api = axios.create({
            baseURL: this.BASE_URL + '/v3',
            timeout: 1000 * 30,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {}
        });
        this.api.defaults.withCredentials = true;

        // refresh token 요청용 API 인스턴스 (lab URL)
        this.refreshApi = axios.create({
            baseURL: 'https://lab.fnsvalue.co.kr/api/v3',
            timeout: 1000 * 30,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {}
        });
        this.refreshApi.defaults.withCredentials = true;

        this.api.interceptors.request.use(config => {
            config.metadata = {startTime: new Date()};
            if (this.accessToken != null && this.accessToken.length > 0) {
                config.headers['Authorization'] = this.accessToken;
            }
            return config;
        });

        this.api.interceptors.response.use(
            async (response) => {
                const { rtCode } = response.data;
                if (rtCode !== ErrCode.RT_SUCCESS) {
                    if (rtCode === 2100) {
                        if (!this.refreshCall) {
                            this.refreshCall = this.handleTokenRefresh();
                        }
                        try {
                            const refreshedTokenResponse = await this.refreshCall;
                            this.accessToken = refreshedTokenResponse.data.data;
                            response.config.headers['Authorization'] = this.accessToken;
                            this.refreshCall = null;
                            return await this.api.request(response.config);
                        } catch (error) {
                            this.refreshCall = null;
                            return Promise.reject(error);
                        }
                    }
                    response.data.rtMsg = this.getErrMsg(rtCode);
                    return Promise.reject(response.data);
                }
                if (response.data.accessToken !== undefined) {
                    this.accessToken = response.data.token;
                }
                return response;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    // refreshCall 전역 변수를 사용하여 중복 요청 방지 (생성자에서 refreshCall 변수 초기화)
    refreshCall = null;

    async handleTokenRefresh(error) {
        try {
            if (this.refreshInProgress) {
                console.error('Refresh attempt already in progress. Aborting.');
                throw new Error('Refresh attempt already in progress.');
            }
            this.refreshInProgress = true;
            const storedRefreshToken = storage.getSession(SK.REFRESH_TOKEN);
            if (!storedRefreshToken) {
                console.error('Refresh token is missing.');
                throw new Error('Refresh token is missing.');
            }
            const requestBody = { token: this.refreshToken };
            // refresh token 갱신 요청: refreshApi 사용 (lab URL)
            const refreshedTokenResponse = await this.refreshApi.put('/me/refreshToken', requestBody);
            const newAccessToken = refreshedTokenResponse.data.data;
            if (newAccessToken) {
                if (refreshedTokenResponse.data.refreshToken) {
                    this.refreshToken = refreshedTokenResponse.data.refreshToken;
                    storage.setSession(SK.REFRESH_TOKEN, this.refreshToken);
                }
                this.accessToken = newAccessToken;
                storage.setSession(SK.ACCESS_TOKEN, this.accessToken);
            } else {
                console.error('Refresh 응답에서 accessToken을 찾지 못했습니다:', refreshedTokenResponse.data);
                throw new Error('Access token not found in the refresh response.');
            }
            return refreshedTokenResponse;
        } catch (refreshError) {
            console.error('Error refreshing access token:', refreshError);
            throw refreshError;
        } finally {
            this.refreshInProgress = false;
        }
    }

    encodeParams = (data, nonNull = false) => {
        return Object
            .keys(data)
            .map(key => {
                    if (!nonNull || data[key] !== null) {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
                    }
                    return null;
                }
            )
            .filter(value => value !== null)
            .join('&');
    };

    getErrMsg = (errCode) => {
        return errCode;
    };

    /*setToken = (token) => {
        this.token = token;
        storage.setSession(SK.ACCESS_TOKEN, token);
    };*/

    setToken = ({ accessToken, refreshToken }) => {
        const tokenData = {
            accessToken,
            refreshToken
        };
        this.accessToken = tokenData.accessToken;
        this.refreshToken= tokenData.refreshToken;
        storage.setSession(SK.ACCESS_TOKEN, tokenData.accessToken);
        storage.setSession(SK.REFRESH_TOKEN, tokenData.refreshToken);

    };

    hasToken = () => {
        return this.accessToken !== null;
    };

    getMe = () => {
        return new Promise((resolve, reject) => {
            this.api.get('https://lab.fnsvalue.co.kr/api/v3/me')
                .then(res => {
                    let user = new User(res.data.data);
                    store.setUser(user);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getCodeGroupList = (keyword = null, page = 0, size = 8) => {
        return new Promise((resolve, reject) => {
            this.api.get(this.BASE_URL + `/common/code/group?${this.encodeParams({keyword, page: page, size: size}, true)}`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    addCodeGroup(name, code, description=null) {
        return new Promise((resolve, reject) => {
            this.api.post(this.BASE_URL + '/common/code/group', {name : name, code : code, description : description})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deleteCodeGroup(seq) {
        return new Promise((resolve, reject) => {
            this.api.delete(this.BASE_URL + `/common/code/group/${seq}`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateCodeGroup(seq, name, description) {
        return new Promise((resolve, reject) => {
            this.api.put(this.BASE_URL + `/common/code/group/${seq}`, {name : name, description : description})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getCodeList = (groupSeq, keyword = null, page = 0, size = 5) => {
        return new Promise((resolve, reject) => {
            this.api.get(this.BASE_URL + `/common/code/${groupSeq}?${this.encodeParams({keyword, page:page, size:size}, true)}`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getSortedCodeList  = (groupSeq) => {
        return new Promise((resolve, reject) => {
            this.api.get(this.BASE_URL + `/common/code/sort/${groupSeq}`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateSortedCodeList = (seq, codeList) => {
        return new Promise((resolve, reject) => {
            this.api.put(this.BASE_URL + `/common/code/sort/${seq}`, {sortList: codeList})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    addCode(groupSeq, name, code, description=null) {
        return new Promise((resolve, reject) => {
            this.api.post(this.BASE_URL + '/common/code', {groupSeq: groupSeq, name : name, code : code, description : description})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deleteCode(seq) {
        return new Promise((resolve, reject) => {
            this.api.delete(this.BASE_URL + `/common/code/${seq}`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateCode(seq, name, description) {
        return new Promise((resolve, reject) => {
            this.api.put(this.BASE_URL + `/common/code/${seq}`, {name : name, description : description})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getCategoryList  = (keyword = null, type = null) => {
        return new Promise((resolve, reject) => {
            this.api.get(this.BASE_URL + `/category?${this.encodeParams({keyword: keyword, type: type}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    addCategory = (name, type, description, optionList) => {
        return new Promise((resolve, reject) => {
            this.api.post(this.BASE_URL + '/category', {name : name, type: type, description : description, optionList: optionList})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateCategory = (categoryList) => {
        return new Promise((resolve, reject) => {
            this.api.put(this.BASE_URL + `/category`, {categoryList: categoryList})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    updateSortedCategoryList = (sortList) => {
        return new Promise((resolve, reject) => {
            this.api.put(this.BASE_URL + `/category/sort`, {sortList: sortList})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deleteCategory = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.delete(this.BASE_URL + `/category/${seq}`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    addBoardGroup = (seq, imgFiles, uploadFiles, boardData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        let formData = new FormData();
        imgFiles?.length > 0 && imgFiles.map((img) => formData.append('boardImgFiles', img));
        uploadFiles?.length > 0 && uploadFiles.map((file) => formData.append('boardFiles', file));
        formData.append("json", new Blob([JSON.stringify(boardData)], {type: "application/json"}))

        return new Promise((resolve, reject) => {
            this.api.post(this.BASE_URL + `/category/${seq}/board`, formData, config)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    addBoardByLanguage = (categorySeq, boardGroupSeq, boardData, imgFiles, files) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        let formData = new FormData();
        imgFiles?.length > 0 && imgFiles.map((img) => formData.append('boardImgFiles', img));
        files?.length > 0 && files.map((file) => formData.append('boardFiles', file));
        formData.append("json", new Blob([JSON.stringify(boardData)], {type: "application/json"}))

        return new Promise((resolve, reject) => {
            this.api.post(this.BASE_URL + `/category/${categorySeq}/board/${boardGroupSeq}/language`, formData, config)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateBoardByLanguage = (categorySeq, boardSeq, seq, boardData, addImgFiles, addFiles) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        let formData = new FormData();
        if (addImgFiles!==undefined) addImgFiles?.length > 0 && addImgFiles.map((img) => formData.append('addImgFiles', img));
        if (addFiles!==undefined) addFiles?.length > 0 && addFiles.map((file) => formData.append('addFiles', file));
        formData.append("json", new Blob([JSON.stringify(boardData)], {type: "application/json"}))

        return new Promise((resolve, reject) => {
            this.api.put(this.BASE_URL + `/category/${categorySeq}/board/${boardSeq}/language/${seq}`, formData, config)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getBoardGroupList = (categorySeq, keyword, visible, uptDt, regDt, page= 0, size = 7) => {
        return new Promise((resolve, reject) => {
            this.api.get(this.BASE_URL + `/category/${categorySeq}/board?${
                this.encodeParams({keyword: keyword, visible: visible, uptDt: uptDt,  regDt: regDt, page:page, size:size}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getSortedBoardList = (categorySeq) => {
        return new Promise((resolve, reject) => {
            this.api.get(this.BASE_URL + `/category/${categorySeq}/board/sort`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getBoardDetail = (categorySeq, seq) => {
        return new Promise((resolve, reject) => {
            this.api.get(this.BASE_URL + `/category/${categorySeq}/board/${seq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getBoardByLanguage = (categorySeq, boardGroupSeq, seq) => {
        return new Promise((resolve, reject) => {
            this.api.get(this.BASE_URL + `/category/${categorySeq}/board/${boardGroupSeq}/language/${seq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateBoardStatus = (categorySeq, seq, visible) => {
        return new Promise((resolve, reject) => {
            this.api.put(this.BASE_URL + `/category/${categorySeq}/board/${seq}`, {visible: visible})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deleteBoardGroup = (categorySeq, seq) => {
        return new Promise((resolve, reject) => {
            this.api.delete(this.BASE_URL + `/category/${categorySeq}/board/${seq}`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deleteBoardByLanguage = (categorySeq, boardGroupSeq, seq) => {
        return new Promise((resolve, reject) => {
            this.api.delete(this.BASE_URL + `/category/${categorySeq}/board/${boardGroupSeq}/language/${seq}`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateSortedBoardList = (categorySeq, sortList) => {
        return new Promise((resolve, reject) => {
            this.api.put(this.BASE_URL + `/category/${categorySeq}/board/sort`, sortList)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateSortedImageList = (categorySeq, boardGroupSeq, seq, sortList) => {
        return new Promise((resolve, reject) => {
            this.api.put(this.BASE_URL + `/category/${categorySeq}/board/${boardGroupSeq}/language/${seq}/img/sort`, sortList)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

}

export default new AdminAPI();